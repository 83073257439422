<template>
    <div class="notFound">
        <div class="img"><img src="@/assets/images/404.png" alt=""></div>
        <div class="tip">{{$t('notFount.msg')}}</div>
        <div class="g_btn btn" @click="back">{{$t('notFount.backBtn')}}</div>
    </div>
</template>
<script>
import { getToken } from '@/utils/auth'
export default {
    methods:{
        back(){
            if (!!getToken()) {
                this.$router.push({
                    path:'/stocks'
                })
            }else{
                this.$router.push({
                    path:'/login'
                })
            }
        },
    }
}
</script>
<style lang="stylus" scoped>
.notFound{
    height: 100vh
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    .img{
        font-size 0
        width 200px
        margin-bottom 18px
        img{
            width 100%
        }
    }
    .tip{
        font-size: 16px
        font-weight: 400
        color: var(--emptyFont)
        margin-bottom: 20px
    }
    .btn{
        color var(--fontWhite)
        width 124px
        height 32px
        background var(--active)
    }
}
</style>